@import url("https://fonts.googleapis.com/css?family=Raleway");
@import url("https://fonts.googleapis.com/css?family=Work Sans");
@import url("https://fonts.googleapis.com/css?family=Spline Sans Mono");
@import url("https://fonts.googleapis.com/css?family=Inter");

@media screen and (max-width: 1200px) {
  footer {
    font-size: 0.8rem;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.foreground {
  position: relative;
  z-index: 1;
}

h1 {
  margin: 0;
  text-shadow: '3px 3px black';
}

p {
  margin: 0;
  text-shadow: '3px 3px black';
}

footer {
  font-family: 'Work Sans', sans-serif;
}

.hello-world {
  font-family: 'Spline Sans Mono', monospace;
}

.github-project:hover {
  filter: invert(1);
  transition: transform ease-in-out 0.2s;
  transform: translateY(-8px);
  border-radius: 50%;
}

.github-project:not(:hover){
  transition: transform 0.2s ease-in-out;
}

.project-live-link {
  height: 50px;
}

.project-live-link:hover {
  filter: invert(1);
  transition: transform ease-in-out 0.2s;
  transform: translateY(-8px);
  border-radius: 5%;
}

.project-live-link:not(:hover){
  transition: transform 0.2s ease-in-out;
}

#brevo {
  height: 4em;
  background-color: rgba(255, 255, 255, 0.10);
  border-radius: 2px;
  padding: 2px 4px 2px 4px;
}